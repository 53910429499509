<template>
    <div class="NoticeList">
        <div class="main">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>招商公告</el-breadcrumb-item>
            </el-breadcrumb>
            <el-card >
                <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
                    <el-form-item label="商品名称">
                        <el-input v-model="dataForm.goodsname" placeholder="商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目编号">
                        <el-input v-model="dataForm.projectno" placeholder="项目编号"></el-input>
                    </el-form-item>
                    <el-form-item label="分类" prop="targettypecascaderlist" >
                        <el-cascader
                                placeholder="分类"
                                v-model="targettypecascaderlist"
                                :options="options"
                                clearable
                                >
                        </el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <el-button  type="primary" @click="getDataList()">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" @click="reset">重置</el-button>
                    </el-form-item>
                    <div class="type2">
                        <span>所在地：</span>
                        <span
                                v-for="(i, index) in a1s"
                                :key="index"
                                :class="activeClass99 == index || hoverClass99 == index ? 'myactived' : ''"
                                @click="getactiveClass(99, index,i)"
                                v-on:mouseover="gethoverClass(99, index)"
                                v-on:mouseout="closeClass(99)"
                        ></span>
                        <span>
          <el-select
                  v-model="provincecode"
                  @change="selectProvince"
                  filterable
                  remote
                  placeholder="请选择省份"
                  style="flex: 1"
                  clearable
          >
            <el-option
                    v-for="itemp in provincelist"
                    :key="itemp.code"
                    :label="itemp.province"
                    :value="itemp.code"
            />
          </el-select></span>
                        -
                        <span>
          <el-select
                  v-model="citycode"
                  @change="selectCity"
                  filterable
                  remote
                  placeholder="请选择市"
                  style="flex: 1"
                  :disabled="cityflg"
                  clearable
          >
            <el-option
                    v-for="itemc in citylist"
                    :key="itemc.code"
                    :label="itemc.city"
                    :value="itemc.code"

            />
          </el-select></span>
                        <!-- </span> -->
                    </div>
                </el-form>
<!--                <el-container>-->
<!--                <el-main>查询条件：商品名称 项目编号，所在地（可参考竞价大厅），分类(字段targettypecascader ：值对应字典)</el-main>-->
<!--                <el-aside width="200px">查询 和  重置按钮</el-aside>-->
<!--                </el-container>-->
            </el-card>
            <el-card class="box-card">
                <!-- <div class="main-title">招商公告</div> -->
                <div class="main-body">
                    <div class="body-table">
                        <div class="table-list">
                            <el-table
                                    :data="zhaoShangList"
                                    stripe
                                    @row-click="OneZhaoShang"
                                    style="width: 100%">
                                <el-table-column align="center" width="80" prop="thumb" label="商品名称">
                                    <template scope="scope">
                                        <img v-if="scope.row.thumb != ''&& scope.row.thumb != null" :src="myPic(scope.row.thumb)" width="70" height="50" />
                                        <img v-else :src="require('../assets/imgs/cart-empty.png')"  width="70" height="50" />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="goodsname"
                                        label=""
                                        :show-overflow-tooltip='true'
                                        width="380">
                                </el-table-column>
                                <el-table-column
                                        prop="projectno"
                                        label="项目编号"
                                        :show-overflow-tooltip='true'
                                        width="220">
                                </el-table-column>
                                <el-table-column
                                        prop="targettypecascader"
                                        label="分类">
                                         <template slot-scope="scope">
                                            <span >{{mytargetType(scope.row.targettypecascader)}}</span>
                                        </template>
                                </el-table-column>
                                 <el-table-column
                                        prop="overprice"
                                        label="价格">
                                </el-table-column>
                                <el-table-column
                                        prop="targetaddressname"
                                        label="所在地">
                                </el-table-column>
                                <el-table-column
                                        prop="releasetime"
                                        label="发布时间">
                                    <template slot-scope="scope">
                                        {{scope.row.releasetime.substring(0,10)}}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!--    分页-->
                        <div class="block" align="right">
                            <el-pagination
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageIndex"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    :total="totalPage"
                                    layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import {CodeToText} from "element-china-area-data";

    export default {
        data() {
            return {
                hoverClass99: "",
                hoverClass: "",
                activeClass99: '',
                towncode: '',
                provincecode:'',
                citycode: '',
                townflg: true,
                cityflg: true,
                fjzcdis:false,
                townlist:[],
                citylist:[],
                activeClass: "",
                provincelist:[],
                zhaoShangList: [],
                selectedOptions: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                forsearch:{
                    outbidstate: '',
                    targettypecascader:'',
                    provincecode:'',
                    city: '',
                    town: '',

                },
                options: [{
                    value: '11',
                    label: '产权机构',
                    children: [{
                        value: 'a',
                        label: '产权转让'
                    }, {
                        value: 'b',
                        label: '资产处置'
                    }]
                }, {
                    value: '22',
                    label: '自行处置'
                }, {
                    value: '33',
                    label: '资产租赁'
                }],
                targettypecascaderlist:[],
                //分页数据
                dataForm: {
                    productid: '',
                    delivery: '',
                    outbidstate: '',
                    splitstate: '',
                    goodsname:'',
                    projectno:'',
                    targettypecascader:''
                },
                dataListLoading: false,
                qualityTableStyle: {
                    'background': 'whitesmoke',
                    'font-weight': 'bold',
                    'font-size': ' 12px',
                    'font-family': "Microsoft YaHei",
                    'color': '#333333',
                    'text-align': 'center',
                },
                a1s: [
                    { name: "不限", type: "", classid: 1 }
                ],
            }
        },
        activated(){
            this.getDataList()
        },
        created() {
            this.getArealist('0', '')
        },
        mounted() {
        },
        methods: {
            // 省市县
            handleChange() {
                var loc = "";
                for (let i = 0; i < this.selectedOptions.length; i++) {
                    loc += CodeToText[this.selectedOptions[i]];
                }
            },
            // 地区编码
            getArealist (key, code) {
                const params = {
                    key: key,
                    code: code
                }
                this.$fetch('/businessapi/sysarea/listinfo',  params ,true).then((res) => {
                    if (res.code !== 0) {
                        return
                    }
                    if (key === '0') {
                        this.provincelist = res.data.listinfop
                    }
                    if (key === '1') {
                        this.citylist = res.data.listinfoc
                        this.cityflg = false
                    }
                    if (key === '2') {
                        this.townlist = res.data.listinfot
                        this.townflg = false
                    }
                }).catch(() => {})
            },
            selectTown(val) {
                if(this.towncode ==null || this.towncode == ''){
                    this.notifyError('请先选择市');
                    return false
                }
                this.activeClass99 = -1
                this.hoverClass99 = -1
                this.index99 = 88
                this.forsearch.town=this.towncode
                this.$parent.$parent.getsearch(this.forsearch)
            },
            selectCity (val) {
                this.index99 = 88
                this.activeClass99 = -1
                this.hoverClass99 = -1
                this.townlist = []
                this.towncode = ''
                this.getArealist('2', val)
                this.getDataList1(val)
                this.forsearch.city=this.citycode
                this.$parent.$parent.getsearch(this.forsearch)
            },
            selectProvince (val) {
                this.activeClass99 = -1
                this.hoverClass99 = -1
                this.forsearch.city = ''
                this.citylist = []
                this.citycode = ''
                this.townlist = []
                this.towncode = ''
                this.townflg = true
                this.getArealist('1', val)
                this.forsearch.provincecode=this.provincecode
                this.$parent.$parent.getsearch(this.forsearch)

            },
            closeClass(type) {
                //  this.fjzcdis = false
                if (type === 1) {
                    //  if(index==1){
                    //   this.fjzcdis = true
                    // }
                    this.hoverClass = -1;
                } else if (type === 2) {
                    this.hoverClass2 = -1;
                }else if (type === 4) {
                    this.hoverClass4 = -1;
                }else if (type === 99) {
                    this.hoverClass99 = -1;
                }
            },
            gethoverClass(type, index) {
                //  this.fjzcdis = false
                if (type === 1) {
                    if(index==1){
                        this.fjzcdis = true
                    }
                    this.hoverClass = index;
                } else if (type === 2) {
                    this.hoverClass2 = index;
                    // this.fjzcdis = false
                }else if (type === 4) {
                    this.hoverClass4 = index;
                    // this.fjzcdis = false
                }else if (type === 99) {
                    this.hoverClass99 = index;
                    // this.fjzcdis = false
                }else if (type === '') {
                    this.hoverClass = index;
                    // this.fjzcdis = false
                }
            },
            // type = 分类  拍卖状态等
            getactiveClass(type, index,e) {
                this.forsearch.targettypecascader = ''
                this.fjzcdis =false
                if (type == 1) {
                    if(index==1){
                        this.fjzcdis =true
                    }
                    this.type1 = e.type
                    this.forsearch.targettypecascader = this.type1 +","+"a"
                    this.activeClass = index;
                    this.hoverClass = index;
                    this.$parent.$parent.getsearch(this.forsearch)
                } else if (type === 2) {
                    this.activeClass2 = index;
                    this.hoverClass2 = index;
                    this.forsearch.outbidstate = e.type
                    this.$parent.getsearch(this.forsearch)
                } else if (type === 4) {
                    this.type2 =e.type
                    this.fjzcdis =true
                    this.activeClass = 1
                    this.hoverClass = 1
                    this.activeClass4 = index;
                    this.hoverClass4 = index;
                    this.forsearch.targettypecascader ='11,' +this.type2
                    this.$parent.getsearch(this.forsearch)

                }  else if (type === 99) {
                    this.activeClass99 = index
                    this.forsearch.provincecode = ''
                    this.provincecode = ''
                    this.forsearch.towncode = ''
                    this.forsearch.citycode = ''
                    this.citycode= '',
                        this.towncode= '',
                        this.cityflg = true,
                        this.townflg = true,
                        this.$parent.$parent.getsearch(this.forsearch)

                }else{
                    this.forsearch.outbidstate = ''
                    this.forsearch.targettypecascader = ''
                }
            },
            // 获取数据列表
            getDataList() {
                this.dataForm.targettypecascader=this.targettypecascaderlist[this.targettypecascaderlist.length-1]
                this.$fetch('/shoppingui/memberEntShopping/pageList', {
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'goodsname':this.dataForm.goodsname,
                    'projectno':this.dataForm.projectno,
                    'targettypecascader':this.dataForm.targettypecascader
                    // 'pproductid': this.coal,
                    // 'companyname': this.purchasingUnit,
                    // 'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]),
                    // 'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1])
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.zhaoShangList = data.data.list;
                        this.totalPage = data.data.total;
                    } else {
                        this.zhaoShangList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })

            },
            getDataList1(i) {
                this.dataForm.targettypecascader=this.targettypecascaderlist[this.targettypecascaderlist.length-1]
                const ii=i.substring(0,4)
                this.$fetch('/shoppingui/memberEntShopping/pageList', {
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'goodsname':this.dataForm.goodsname,
                    'projectno':this.dataForm.projectno,
                    'tar':this.dataForm.targettypecascader,
                    'targetaddress':ii
                    // 'pproductid': this.coal,
                    // 'companyname': this.purchasingUnit,
                    // 'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]),
                    // 'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1])
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.zhaoShangList = data.data.list;
                        this.totalPage = data.data.total;
                    } else {
                        this.zhaoShangList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })

            },
            reset(){
                this.dataForm.projectno='',
                this.dataForm.goodsname='',
                this.targettypecascaderlist=[],
                this.provincecode='',
                this.citycode='',
                this.cityflg = true,
                this.getDataList()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                //调用查询信息接口
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                //调用查询信息接口
                this.getDataList()
            },
            gotoDetails(name, url,title) {
                let queryData = {};
                queryData.url = url;
                queryData.newsReleaseName = name;
                queryData.title = title;
                this.$router.push({path: "/zxXiangQing", query: queryData});
            },
            OneZhaoShang(row){
                let queryData = {};
                queryData.id = row.id;
                this.$router.push({path: "/zhaoshangone", query: queryData});
            },
              myPic(e){
                if(e.length >0){
                return this.$ImgUrl+e[0]
                }
                },
                 mytargetType(e){
                if(e!=null){
                    if(e.substr(0,2)=='11'){
                    return '产权机构'
                    }else if(e.substr(0,2)=='22'){
                    return '自行处理'
                    }else if(e.substr(0,2)=='33'){
                    return '资产租赁'
                    }else if(e.includes('a')){
                    return '产权转让'
                    }else if(e.includes('b')){
                    return '资产处置'
                    }else {
                    return '其他'
                    }
                }
            }

        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/bidding.scss";
    @import "../assets/css/page/NoticeList.scss";
    /* 更改element-UI按钮样式 */
    .el-button--text {
        color: #b0b0b0;
    }

    .el-button--text:hover {
        color: #b0b0b0;
        font-weight: bold;
    }

    .el-button--text:focus {
        color: #000000;
        font-weight: bold;
    }
   .el-table {
    /deep/tbody tr:hover>td {
        cursor: pointer;
        background-color:#fde8ea;
        // font-size: 18px;
        font-weight: 500;
        }
    }
    // .card_imgsize{
    //   width: 65px;
    //   height: 65px;
    //   object-fit: cover;
    // }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 100px;
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 100px;
  }

</style>
